export class ScrollLock {
    public isLocked = false;

    private bodyScrollPosition = 0;
    private readonly touchEventsForStopPropagation: string[] = ['touchstart', 'touchmove', 'touchend'];
    private readonly basketModalContainerId: string;

    constructor(basketModalContainerId: string) {
        this.basketModalContainerId = basketModalContainerId;
    }

    private get hasParallax(): boolean {
        return document.body.classList.contains('parallax') || document.body.classList.contains('parallax2');
    }

    private get usingSkrollr(): boolean {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        return typeof window['skrollr'] !== 'undefined';
    }

    private touchEventStopPropagationEventHandler(event: Event): void {
        event.stopPropagation();
    }

    private stopParallaxTouchEvents() {
        const container = document.getElementById(this.basketModalContainerId);
        if (container) {
            this.touchEventsForStopPropagation.map((eventName: string) =>
                document.body.addEventListener(eventName, this.touchEventStopPropagationEventHandler)
            );
        }
    }

    private restoreParallaxTouchEvents() {
        const container = document.getElementById(this.basketModalContainerId);
        if (container) {
            this.touchEventsForStopPropagation.map((eventName: string) =>
                document.body.removeEventListener(eventName, this.touchEventStopPropagationEventHandler)
            );
        }
    }

    public lock(scrollPosition?: number): void {
        const webContainer = document.querySelector('.container');
        const mobileContainer = document.querySelector('.page-container');
        this.bodyScrollPosition = typeof scrollPosition === 'number' ? scrollPosition : window.scrollY;

        if (webContainer || mobileContainer) {
            document.body.classList.add('bm_scroll_lock')
            if (!this.hasParallax) {
                document.body.style.setProperty('top', '-' + this.bodyScrollPosition + 'px');
            } else if (this.hasParallax && this.usingSkrollr) {
                this.stopParallaxTouchEvents();
            }
            this.isLocked = true;
        }
    }

    public unlock(): void {
        const webContainer = document.querySelector('.container');
        const mobileContainer = document.querySelector('.page-container');

        if (webContainer || mobileContainer) {
            document.body.classList.remove('bm_scroll_lock')
            if (!this.hasParallax) {
                document.body.style.removeProperty('top');
                window.scrollTo(0, this.bodyScrollPosition);
            } else if (this.hasParallax && this.usingSkrollr) {
                this.restoreParallaxTouchEvents();
            }
            this.isLocked = false;
        }
    }
}
