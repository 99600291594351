import React from 'react';
import { ICrossSell } from '../../../types/properties';
import style from './crossSellUnit.scss';
import * as helper from '../../helper/html-helper';
import { isMobile } from 'react-device-detect';

export default class CrossSellUnit extends React.Component<ICrossSell> {
    private crossSellUnitRef = React.createRef<HTMLAnchorElement>();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(this.crossSellUnitRef && this.crossSellUnitRef.current) {
            this.crossSellUnitRef.current.setAttribute('focusable', '');
        }
    }

    private taxMode(): string {
        if (this.props.priceMode.length > 0) {
            const realValue = this.props.priceMode === 'Net' ? 
                this.props.localization.excludeText : this.props.localization.includeText;
            return '(' + helper.decodeHTML(realValue) + ')';
        } else
            return null;
    }

    private showPriceText(): string {
        if (this.props.priceMode.length > 0) {
            let realValue = this.props.bestPrice.minimalPrice.netValue;
            if (!this.props.isGlobal) {
                realValue = this.props.priceMode === 'Net' ? 
                    this.props.bestPrice.minimalPrice.netValue : this.props.bestPrice.minimalPrice.grossValue;
            }
            let priceText = '';
            if (isMobile) {
                priceText = this.props.bestPrice.minimalPrice.prependCurrencySymbolMobile ?
                    `${this.props.bestPrice.minimalPrice.currencySymbolMobile} ${realValue}` :
                    `${realValue} ${this.props.bestPrice.minimalPrice.currencySymbolMobile}`;
            } else {
                priceText = this.props.bestPrice.minimalPrice.prependCurrencySymbolWeb ?
                    `${this.props.bestPrice.minimalPrice.currencySymbolWeb} ${realValue}` :
                    `${realValue} ${this.props.bestPrice.minimalPrice.currencySymbolWeb}`;
            }

            const priceType = this.props.bestPrice.minimalPrice.priceType === 'from' ?
            this.props.localization.priceFrom : this.props.localization.priceOnly;
            return helper.decodeHTML(priceType + ' ' + priceText);
        } else
            return null;
    }

    private quantityString(): string {
        return helper.decodeHTML(this.props.localization.quantityFrom) + ' ' + this.props.bestPrice.minimalPrice.quantity;
    }

    private comparePriceText(): string {
        if (this.props.bestPrice.comparisonPrice && this.props.priceMode.length > 0) {
            const realValue = this.props.priceMode === 'Net' ?
            this.props.bestPrice.comparisonPrice.netValue : this.props.bestPrice.comparisonPrice.grossValue;
            let priceValue = '';
            if (isMobile) {
                priceValue = this.props.bestPrice.comparisonPrice.prependCurrencySymbolMobile ?
                    `${this.props.bestPrice.comparisonPrice.currencySymbolMobile} ${realValue}` :
                    `${realValue} ${this.props.bestPrice.comparisonPrice.currencySymbolMobile}`;
            } else {
                priceValue = this.props.bestPrice.comparisonPrice.prependCurrencySymbolWeb ?
                    `${this.props.bestPrice.comparisonPrice.currencySymbolWeb} ${realValue}` :
                    `${realValue} ${this.props.bestPrice.comparisonPrice.currencySymbolWeb}`;
            }

            return helper.decodeHTML(this.props.localization.comparePrice + this.props.localization.colon +
                ' ' + priceValue + '/' + this.props.bestPrice.comparisonPrice.unit);
        } else
            return null;
    }

    private detailsPageLinkWithItemOrign(): string {
        const url = new URL(this.props.detailsPageLink);

        // short info about recommendationSource:
        // recommendationSource is manualMaintenance -> CROSS
        // recommendationSource is articleRecommendation -> CROSSRECO

        if(this.props.recommendationSource === 'manualMaintenance')
            url.searchParams.append('itemOrigin', 'CROSS/' + this.props.rootNavKey);
        else 
            url.searchParams.append('itemOrigin', 'CROSSRECO/' + this.props.rootNavKey);
        
        return url.toString();
    }

    private getAltTagValue(): string {
        const colorName = this.props.colorName && this.props.colorName.length > 0 ? ', ' + this.props.colorName : '';
        return this.props.localization.mainView + ', ' + this.props.title + colorName;
    }

    render() {
        return (
            <a className={style.cross_sell_unit} href={this.detailsPageLinkWithItemOrign()}
                data-testid={'detailsPageLinkWithItemOrign'} ref={this.crossSellUnitRef}>
                <div className={style.image}>
                    <img src={helper.parserImageUrl(this.props.mainImageSource, this.props.cdnHost)}
                        alt={this.getAltTagValue()} />
                </div>
                <div className={style.title}>
                    {this.props.title}
                </div>
                {
                    this.props.isGlobal ?
                        <div className={style.price} data-ge-price data-testid={'price_text'}>
                            {this.showPriceText()}
                        </div> :
                        <div className={style.price} data-testid={'price_text'}>
                            {this.showPriceText()}
                        </div>
                }
                {
                    this.props.bestPrice.comparisonPrice &&
                    <div className={style.compare_price}>
                        {this.comparePriceText()}
                    </div>
                }
                {
                    !this.props.isGlobal &&
                    <div className={style.tax_mode} data-testid={'tax_mode'}>
                        {this.taxMode()}
                    </div>
                }
                {this.props.bestPrice.minimalPrice.priceType === 'from' &&
                    <div data-testid={'quantity_text'} className={style.quantity}>
                        {this.quantityString()}
                    </div>
                }
            </a>
        );
    }
}
